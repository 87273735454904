<template>
  <div>
    <div class="standard-page privacy-policy px-4 py-3 px-md-6 py-md-8">
      <!-- show logo and login CTA -->
      <div class="d-flex justify-space-between align-center align-md-start">
        <v-img
          contain
          width="70"
          src="/img/logo.png"
          class="flex-grow-0 cursor-pointer"
          @click="$router.push({ name: 'Home' })"
        />

        <v-spacer />

        <v-btn
          depressed
          outlined
          small
          class="font-weight-bold"
          :to="{ name: 'Login' }"
        >
          {{ isAuthenticated ? "Dashboard" : "Login" }}
        </v-btn>
      </div>

      <div
        class="mt-6 article-content"
        v-if="articleName"
      >
        <component :is="articleName" />
      </div>
    </div>
  </div>
</template>

<script>
// Import the article views
const GeoLocation = () => import(/* webpackChunkName: "articles-geo-location" */ "@/articles/geo-location.vue")
const GenderAndAge = () => import(/* webpackChunkName: "articles-gender-age" */ "@/articles/gender-and-age.vue")
const MinimisingSamplingError = () => import(/* webpackChunkName: "articles-minimising-sampling-error" */ "@/articles/minimising-sampling-error.vue")

// Export the SFC
export default {
  // Name of the component
  name: "ArticlesView",

  // Local data variables
  data: () => ({
    // A map for the slug path and the article to be shown
    slugMap: {
      "geo-location": GeoLocation,
      "gender-and-age": GenderAndAge,
      "minimising-sampling-error": MinimisingSamplingError
    }
  }),

  // Computed data variables
  computed: {
    /**
     * The article view to be shown
     *
     * @returns {String}
     */
    articleName() {
      return this.slugMap[this.$route.params.slug]
    },

    /**
     * Whether or not the user is logged in
     *
     * @returns {Boolean}
     */
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]
    }
  },
}
</script>

<style lang="stylus">
  .article-content {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;

    h1 {
      font-size: 50px;
      margin-bottom: 17px;
      color: #333;
    }

    h2 {
      font-size: 24px;
      line-height: 1.6;
      margin: 30px 0 0 0;
      margin-bottom: 18px;
      margin-top: 33px;
      color: #333;
    }

    h3 {
      font-size: 30px;
      margin: 10px 0 20px 0;
      color: #333;
    }

    header {
      width: 640px;
      margin: auto;
    }

    section {
      width: 640px;
      margin: auto;
    }

    section p {
      margin-bottom: 27px;
      font-size: 20px;
      line-height: 1.6;
      color: #333;
    }

    section ul, section ol {
      font-size: 20px;
      line-height: 1.6;
      color: #333;
    }

    section img {
      max-width: 640px;
    }

    footer {
      padding: 0 20px;
      margin: 50px 0;
      text-align: center;
      font-size: 12px;
    }

    .aspectRatioPlaceholder {
      max-width: auto !important;
      max-height: auto !important;
    }

    .aspectRatioPlaceholder-fill {
      padding-bottom: 0 !important;
    }

    header,
    section[data-field=subtitle],
    section[data-field=description] {
      display: none;
    }
  }
</style>